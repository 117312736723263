import React from 'react';
import { 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Typography,
  Paper,
  Box 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSalary } from '../context/SalaryContext';
import { trackFAQView } from '../firebase/analytics';

export function FAQ() {
  const { t, selectedYear } = useSalary();

  const faqs = [
    // Basic Information
    {
      question: t('faq.netSalaryCalc.question', { year: selectedYear }),
      answer: t('faq.netSalaryCalc.answer', { year: selectedYear })
    },
    {
      question: t('faq.taxBrackets.question', { year: selectedYear }),
      answer: t('faq.taxBrackets.answer', { year: selectedYear })
    },
    {
      question: t('faq.minimumWage.question', { year: selectedYear }),
      answer: t('faq.minimumWage.answer', { year: selectedYear })
    },
    // Detailed Examples and Calculations
    {
      question: t('faq.detailedExample.question'),
      answer: t('faq.detailedExample.answer')
    },
    {
      question: t('faq.taxCalculationDetail.question'),
      answer: t('faq.taxCalculationDetail.answer')
    },
    // Historical Data and Comparisons
    {
      question: t('faq.historicalComparison.question'),
      answer: t('faq.historicalComparison.answer')
    },
    {
      question: t('faq.yearComparison.question', { year: selectedYear }),
      answer: t('faq.yearComparison.answer', { year: selectedYear })
    },
    // Technical Details and Advanced Scenarios
    {
      question: t('faq.technicalDetails.question'),
      answer: t('faq.technicalDetails.answer')
    },
    {
      question: t('faq.advancedScenarios.question'),
      answer: t('faq.advancedScenarios.answer')
    },
    // Deductions and Premiums
    {
      question: t('faq.sgkPremium.question'),
      answer: t('faq.sgkPremium.answer')
    },
    {
      question: t('faq.stampTax.question'),
      answer: t('faq.stampTax.answer')
    },
    {
      question: t('faq.sgkCeiling.question'),
      answer: t('faq.sgkCeiling.answer')
    },
    // Special Cases
    {
      question: t('faq.employerCosts.question'),
      answer: t('faq.employerCosts.answer')
    },
    {
      question: t('faq.bonusCalculation.question'),
      answer: t('faq.bonusCalculation.answer')
    }
  ];

  const handleAccordionChange = (question) => (event, isExpanded) => {
    if (isExpanded) {
      trackFAQView(question);
    }
  };

  return (
    <Paper 
      elevation={1}
      sx={{ 
        padding: 3,
        borderRadius: 1
      }}
    >
      <Typography 
        variant="h6" 
        component="h2" 
        gutterBottom
        sx={{ mb: 3 }}
      >
        {t('faq.title')}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {faqs.map((faq, index) => (
          <Accordion 
            key={index}
            onChange={handleAccordionChange(faq.question)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-content-${index}`}
              id={`faq-header-${index}`}
            >
              <Typography>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ whiteSpace: 'pre-line' }}>
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Paper>
  );
} 