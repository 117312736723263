import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Paper, Link } from '@mui/material';
import { SalaryProvider, useSalary } from './context/SalaryContext';
import { ThemeProvider } from './context/ThemeContext';
import { YearSelector } from './components/YearSelector';
import { LanguageSelector } from './components/LanguageSelector';
import { ThemeToggle } from './components/ThemeToggle';
import { SalaryInput } from './components/SalaryInput';
import { TaxBrackets } from './components/TaxBrackets';
import { YearNote } from './components/YearNote';
import { SalaryTable } from './components/SalaryTable';
import { GlobalStyles, CssBaseline } from '@mui/material';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { BrowserRouter, Routes, Route, Navigate, useParams, useNavigate } from 'react-router-dom';
import { Breadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { AdUnit } from './components/AdUnit';
import { FAQ } from './components/FAQ';
import { SalaryChart } from './components/SalaryChart';
import { CookieConsent } from './components/CookieConsent';
import { trackYearChange } from './firebase/analytics';

function AppContent() {
  const { t, setSelectedYear } = useSalary();
  const [showPrivacy, setShowPrivacy] = useState(false);
  const { year } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!year) {
      navigate('/2025', { replace: true });
    } else {
      setSelectedYear(year);
    }
  }, [year, setSelectedYear, navigate]);

  const handlePrivacyOpen = () => {
    setShowPrivacy(true);
  };

  const handlePrivacyClose = () => {
    setShowPrivacy(false);
  };

  const handleYearChange = (newYear) => {
    navigate(`/${newYear}`);
    trackYearChange(newYear);
  };

  // Reklam durumunu kontrol et (örnek olarak)
  const isAdsEnabled = process.env.REACT_APP_ADS_ENABLED === 'true';

  return (
    <>
      <CssBaseline />
      <GlobalStyles
        styles={{
          '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
          },
          'html, body': {
            overflowX: 'hidden',
            width: '100%',
            margin: 0,
            padding: 0,
          },
          'input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          'input[type="number"]': {
            MozAppearance: 'textfield',
          },
        }}
      />
      <Container 
        maxWidth={false} 
        disableGutters
        sx={{ 
          padding: { xs: 0.5, sm: 1, md: 2 },
          minHeight: '100vh',
          overflowX: 'hidden',
          width: '100%',
          maxWidth: '100%'
        }}
      >
        <Container 
          maxWidth="lg" 
          disableGutters
          sx={{ 
            overflowX: 'hidden',
            px: { xs: 0.5, sm: 1, md: 2 },
            mx: 'auto',
            width: '100%'
          }}
        >
          {/* Breadcrumb */}
          <Breadcrumbs 
            separator={<NavigateNextIcon fontSize="small" />} 
            aria-label="breadcrumb"
            sx={{ my: 2 }}
          >
            <Link 
              color="inherit" 
              href="/"
              sx={{ textDecoration: 'none' }}
            >
              {t('title')}
            </Link>
            {year && (
              <Typography color="text.primary">{year}</Typography>
            )}
          </Breadcrumbs>

          {/* Header */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: { xs: 'flex-start', sm: 'center' },
            marginY: 3,
            flexDirection: { xs: 'row', sm: 'row' },
            gap: 2
          }}>
            <Box sx={{ flex: 1 }}>
              <Typography 
                variant="h4" 
                component="h1"
                sx={{
                  fontSize: { xs: '1.5rem', sm: '2.125rem' },
                  fontWeight: 500,
                  marginBottom: 1
                }}
              >
                {year ? `${year} ${t('title')}` : t('title')}
              </Typography>
              <Typography
                variant="h2"
                component="h2"
                sx={{
                  fontSize: { xs: '1rem', sm: '1.25rem' },
                  fontWeight: 400,
                  color: 'text.secondary',
                  marginBottom: { xs: 0, sm: 2 }
                }}
              >
                {t('subtitle')}
              </Typography>
            </Box>
            <Box sx={{ 
              display: 'flex', 
              gap: 2, 
              alignItems: 'center',
              alignSelf: 'flex-start'
            }}>
              <ThemeToggle />
              <YearSelector onChange={handleYearChange} />
            </Box>
          </Box>

          {/* Main Content */}
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            gap: 3
          }}>
            {/* Year Note */}
            <YearNote />

            {/* Top Calculator Ad */}
            <AdUnit 
              slot="3984296905"
              format="auto"
              isEnabled={isAdsEnabled} 
            />

            {/* Calculator Section */}
            <Paper sx={{ 
              borderRadius: 1,
              padding: 3,
              boxShadow: 1
            }}>
              <SalaryInput />
              <Box sx={{ marginTop: 3 }}>
                <SalaryTable />
              </Box>
              <Box sx={{ marginTop: 3 }}>
                <SalaryChart />
              </Box>
            </Paper>

            {/* Middle Content Ad */}
            <AdUnit 
              slot="6124107608"
              format="auto"
              isEnabled={isAdsEnabled} 
            />

            {/* Tax Brackets */}
            <TaxBrackets />

            {/* Bottom Content Ad */}
            <AdUnit 
              slot="4047213375"
              format="auto"
              isEnabled={isAdsEnabled} 
            />

            {/* FAQ Section */}
            <FAQ />

            {/* Disclaimer */}
            <Paper 
              elevation={1}
              sx={{ 
                padding: 3,
                borderRadius: 1
              }}
            >
              <Typography variant="body2" color="text.secondary">
                {t('disclaimer')}
              </Typography>
            </Paper>

            {/* Language Selector and Privacy Link */}
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mt: 2
            }}>
              <Link
                component="button"
                variant="body2"
                onClick={() => setShowPrivacy(true)}
                sx={{ textDecoration: 'none' }}
              >
                {t('privacy.title')}
              </Link>
              <LanguageSelector />
            </Box>
          </Box>
        </Container>
      </Container>

      {/* Privacy Policy */}
      <PrivacyPolicy 
        open={showPrivacy}
        onClose={handlePrivacyClose}
      />
      <CookieConsent />
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <SalaryProvider>
          <Routes>
            <Route path="/:year?" element={<AppContent />} />
          </Routes>
        </SalaryProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
