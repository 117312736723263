import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyDpffK3BglCZuWDvIyhecuxtZ57yDdUoNE",
    authDomain: "netsalary-8f9d4.firebaseapp.com",
    projectId: "netsalary-8f9d4",
    storageBucket: "netsalary-8f9d4.firebasestorage.app",
    messagingSenderId: "458563432068",
    appId: "1:458563432068:web:9d375f2171cb8b8533dcd1",
    measurementId: "G-9J9MK2BDY8"
};

export const app = initializeApp(firebaseConfig); 