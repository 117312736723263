export const TAX_CONFIG = {
    "2025": {
        "vergiDilimleri": [
            { "limit": 158000, "oran": 0.15 },
            { "limit": 330000, "oran": 0.20 },
            { "limit": 1250000, "oran": 0.27 },
            { "limit": 4300000, "oran": 0.35 },
            { "limit": null, "oran": 0.40 }
        ],
        "sgkTavanUcret": 195037.5,
        "asgariUcret": 26005,
        "katsayilar": {
            "sgkOrani": 0.14,
            "issizlikSigortasiOrani": 0.01,
            "damgaVergisiOrani": 0.00759
        }
    },
    "2024": {
        "vergiDilimleri": [
            { "limit": 110000, "oran": 0.15 },
            { "limit": 230000, "oran": 0.20 },
            { "limit": 870000, "oran": 0.27 },
            { "limit": 3000000, "oran": 0.35 },
            { "limit": null, "oran": 0.40 }
        ],
        "sgkTavanUcret": 150000,
        "asgariUcret": 20002.50,
        "katsayilar": {
            "sgkOrani": 0.14,
            "issizlikSigortasiOrani": 0.01,
            "damgaVergisiOrani": 0.00759
        }
    }
}; 