import React, { useState, useEffect } from 'react';
import { 
    Snackbar, 
    Button, 
    Box,
    Typography
} from '@mui/material';
import { useSalary } from '../context/SalaryContext';
import { initializeAnalytics } from '../firebase/analytics';

const CONSENT_COOKIE_NAME = 'analytics-consent';

export function CookieConsent() {
    const { t } = useSalary();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const hasConsent = localStorage.getItem(CONSENT_COOKIE_NAME);
        if (hasConsent === null) {
            setOpen(true);
        } else {
            initializeAnalytics(hasConsent === 'true');
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem(CONSENT_COOKIE_NAME, 'true');
        initializeAnalytics(true);
        setOpen(false);
    };

    const handleDecline = () => {
        localStorage.setItem(CONSENT_COOKIE_NAME, 'false');
        initializeAnalytics(false);
        setOpen(false);
    };

    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            sx={{
                '& .MuiPaper-root': {
                    width: '100%',
                    maxWidth: 600,
                    p: 2
                }
            }}
        >
            <Box sx={{ 
                bgcolor: 'background.paper', 
                p: 2, 
                borderRadius: 1,
                boxShadow: 3
            }}>
                <Typography variant="body1" gutterBottom>
                    {t('privacy.analyticCookiesText')}
                </Typography>
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'flex-end',
                    gap: 2,
                    mt: 2 
                }}>
                    <Button 
                        onClick={handleDecline}
                        variant="outlined"
                        color="inherit"
                    >
                        {t('privacy.decline')}
                    </Button>
                    <Button 
                        onClick={handleAccept}
                        variant="contained"
                        color="primary"
                    >
                        {t('privacy.accept')}
                    </Button>
                </Box>
            </Box>
        </Snackbar>
    );
} 