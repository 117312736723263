import React, { useState } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';
import { Paper, Typography, Box, useTheme, IconButton, Collapse } from '@mui/material';
import { useSalary } from '../context/SalaryContext';
import { 
    hesaplaSGKPrimi, 
    hesaplaGelirVergisi,
    hesaplaVergiIstisnasi
} from '../utils/calculations';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export function SalaryChart() {
    const { 
        t, 
        selectedYear,
        TAX_CONFIG,
        monthlyGrossSalaries, 
        formatPara 
    } = useSalary();
    const theme = useTheme();
    const currentConfig = TAX_CONFIG[selectedYear];
    const [expanded, setExpanded] = useState(true);

    // Get the table data from SalaryTable component's calculations
    const chartData = React.useMemo(() => {
        if (!monthlyGrossSalaries || monthlyGrossSalaries.every(salary => !salary)) {
            return [];
        }

        let kumulatifMatrah = 0;
        let asgariUcretKumulatifMatrah = 0;

        return t('months').map((ay, index) => {
            const brutMaas = monthlyGrossSalaries[index] || 0;
            
            if (brutMaas <= 0 || brutMaas < currentConfig.asgariUcret) {
                return {
                    name: ay,
                    [t('faq.salaryChart.grossSalary')]: 0,
                    [t('faq.salaryChart.netSalary')]: 0
                };
            }

            // Calculate all deductions exactly as in SalaryTable
            const sgkKesintisi = hesaplaSGKPrimi(brutMaas, currentConfig.katsayilar.sgkOrani, currentConfig.sgkTavanUcret);
            const issizlikSigortasi = hesaplaSGKPrimi(brutMaas, currentConfig.katsayilar.issizlikSigortasiOrani, currentConfig.sgkTavanUcret);
            const aylikMatrah = brutMaas - (sgkKesintisi + issizlikSigortasi);
            
            const asgariUcretSgkKesintisi = currentConfig.asgariUcret * currentConfig.katsayilar.sgkOrani;
            const asgariUcretIssizlikKesintisi = currentConfig.asgariUcret * currentConfig.katsayilar.issizlikSigortasiOrani;
            const asgariUcretAylikMatrah = currentConfig.asgariUcret - (asgariUcretSgkKesintisi + asgariUcretIssizlikKesintisi);
            
            const hesaplananGelirVergisi = hesaplaGelirVergisi(aylikMatrah, kumulatifMatrah + aylikMatrah, currentConfig.vergiDilimleri);
            const hesaplananDamgaVergisi = brutMaas * currentConfig.katsayilar.damgaVergisiOrani;

            const gelirVergisiIstisnasi = hesaplaVergiIstisnasi(
                brutMaas, 
                aylikMatrah, 
                hesaplananGelirVergisi, 
                'gelir', 
                index, 
                currentConfig.asgariUcret, 
                currentConfig.vergiDilimleri,
                asgariUcretKumulatifMatrah + asgariUcretAylikMatrah
            );
            const damgaVergisiIstisnasi = hesaplaVergiIstisnasi(
                brutMaas, 
                aylikMatrah, 
                hesaplananDamgaVergisi, 
                'damga', 
                index, 
                currentConfig.asgariUcret
            );

            const gelirVergisi = Math.max(0, hesaplananGelirVergisi - gelirVergisiIstisnasi);
            const damgaVergisi = Math.max(0, hesaplananDamgaVergisi - damgaVergisiIstisnasi);
            
            kumulatifMatrah += aylikMatrah;
            asgariUcretKumulatifMatrah += asgariUcretAylikMatrah;

            const netMaas = brutMaas - (sgkKesintisi + issizlikSigortasi + gelirVergisi + damgaVergisi);

            return {
                name: ay,
                [t('faq.salaryChart.grossSalary')]: brutMaas,
                [t('faq.salaryChart.netSalary')]: netMaas
            };
        });
    }, [monthlyGrossSalaries, t, currentConfig, TAX_CONFIG, selectedYear]);

    if (!chartData.length) {
        return null;
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <Paper
                    elevation={3}
                    sx={{
                        p: 2,
                        backgroundColor: theme.palette.background.paper
                    }}
                >
                    <Typography variant="subtitle2" color="text.secondary">
                        {label}
                    </Typography>
                    {payload.map((pld, index) => (
                        <Typography
                            key={index}
                            variant="body2"
                            sx={{ color: pld.color }}
                        >
                            {pld.name}: {formatPara(pld.value)} TL
                        </Typography>
                    ))}
                </Paper>
            );
        }
        return null;
    };

    // Calculate dynamic Y-axis ticks based on salary range
    const maxValue = Math.max(
        ...chartData.map(data => Math.max(
            data[t('faq.salaryChart.grossSalary')] || 0,
            data[t('faq.salaryChart.netSalary')] || 0
        ))
    );

    const calculateTicks = (maxVal) => {
        const roundedMax = Math.ceil(maxVal / 1000) * 1000;
        const interval = Math.ceil(roundedMax / 5 / 1000) * 1000;
        const ticks = [];
        for (let i = 0; i <= roundedMax + interval; i += interval) {
            ticks.push(i);
        }
        return ticks;
    };

    return (
        <Paper
            elevation={1}
            sx={{
                p: 3,
                borderRadius: 1,
                backgroundColor: theme.palette.background.paper
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="h6">
                    {t('faq.salaryChart.title')}
                </Typography>
                <IconButton onClick={() => setExpanded(!expanded)} size="small">
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>
            <Collapse in={expanded}>
                <Box 
                    sx={{ 
                        width: '100%',
                        overflowX: 'auto',
                        overflowY: 'hidden',
                        '&::-webkit-scrollbar': {
                            height: 8
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: theme.palette.background.default
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: 4
                        }
                    }}
                >
                    <Box sx={{ 
                        width: { xs: '800px', sm: '100%' }, // Fixed minimum width on mobile
                        height: 300,
                        minWidth: '800px' // Ensures minimum width is maintained
                    }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                data={chartData}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 80,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="name"
                                    tick={{ fill: theme.palette.text.primary }}
                                />
                                <YAxis
                                    tick={{ fill: theme.palette.text.primary }}
                                    tickFormatter={(value) => `${formatPara(value)} TL`}
                                    ticks={calculateTicks(maxValue)}
                                    domain={[0, maxValue => maxValue * 1.1]}
                                    width={120}
                                />
                                <Tooltip content={<CustomTooltip />} />
                                <Legend />
                                <Line
                                    type="monotone"
                                    dataKey={t('faq.salaryChart.grossSalary')}
                                    stroke={theme.palette.primary.main}
                                    strokeWidth={2}
                                    dot={{ r: 4 }}
                                    activeDot={{ r: 8 }}
                                />
                                <Line
                                    type="monotone"
                                    dataKey={t('faq.salaryChart.netSalary')}
                                    stroke={theme.palette.secondary.main}
                                    strokeWidth={2}
                                    dot={{ r: 4 }}
                                    activeDot={{ r: 8 }}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                </Box>
            </Collapse>
        </Paper>
    );
} 