export const translations = {
    tr: {
        title: "Net Maaş Hesaplama",
        subtitle: "Güncel vergi dilimleri ve asgari ücret muafiyeti dahil detaylı hesaplama",
        calculate: "Hesapla",
        grossSalary: "Brüt Maaş",
        grossSalaryPlaceholder: "Aylık brüt maaşınızı giriniz",
        netSalary: "Net Maaş",
        sgkPremium: "SGK Primi",
        unemploymentInsurance: "İşsizlik Sigortası",
        stampTax: "Damga Vergisi",
        incomeTax: "Gelir Vergisi",
        taxBracket: "Vergi Dilimi",
        month: "Ay",
        total: "Toplam",
        average: "Ortalama",
        grossRatio: "Brüt'e Oran",
        taxBrackets: "{year} Gelir Vergisi Dilimleri",
        andAbove: "ve üzeri",
        note: "Not",
        minimumWageNote: "Asgari ücret altında maaş hesaplaması yapılamaz",
        enterValidSalary: "Lütfen geçerli bir maaş giriniz",
        salaryBelowMinimumWage: "Girdiğiniz maaş asgari ücretin ({asgariUcret} TL) altında olamaz",
        ceiling: "Tavan",
        calculatedStampTax: "Hesaplanan Damga Vergisi",
        deductedStampTax: "İndirimli Damga Vergisi",
        calculatedIncomeTax: "Hesaplanan Gelir Vergisi",
        deductedIncomeTax: "İndirimli Gelir Vergisi",
        minimumWageExemption: "Asgari Ücret İstisnası",
        taxBracketDetails: "Vergi Dilimi Detayları",
        fromBracket: "diliminden",
        months: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
        disclaimer: "Bu hesaplama aracı bilgilendirme amaçlıdır. Hesaplamalar yaklaşık değerler üzerinden yapılmaktadır ve gerçek değerlerden farklılık gösterebilir. Özellikle asgari ücret muafiyeti, vergi dilimleri ve SGK tavan ücreti gibi değerler yıl içinde değişiklik gösterebilir. Kesin bilgi için lütfen mali müşavirinize danışınız veya ilgili resmi kurumların (GİB, SGK) web sitelerini ziyaret ediniz. Bu araç üzerinden yapılan hesaplamalar nedeniyle doğabilecek hiçbir zarardan sorumluluk kabul edilmemektedir.",
        privacy: {
            title: "Gizlilik Politikası",
            lastUpdated: "Son Güncelleme: 28 Kasım 2024",
            introduction: "Giriş",
            introductionText: "Net Maaş Hesaplama aracı olarak gizliliğinize önem veriyoruz. Bu gizlilik politikası, sitemizi nasıl kullandığınız ve verilerinizi nasıl koruduğumuz hakkında bilgi vermektedir.",
            dataCollection: "Veri Toplama ve Kullanım",
            salaryCalculations: "Maaş Hesaplamaları",
            salaryCalculationsText: "Girdiğiniz maaş bilgileri ve yapılan tüm hesaplamalar yalnızca tarayıcınızda yerel olarak gerçekleştirilir. Bu bilgiler sunucularımıza gönderilmez veya herhangi bir şekilde saklanmaz.",
            personalData: "Kişisel Veriler",
            personalDataText: "Sitemiz hiçbir kişisel veri toplamamakta ve saklamamaktadır. Tüm işlemler tarayıcınızda yerel olarak gerçekleştirilir.",
            cookiesAndAnalytics: "Çerezler ve Analitik",
            necessaryCookies: "Zorunlu Çerezler",
            necessaryCookiesText: "Sitenin temel işlevlerini yerine getirebilmesi için gerekli olan minimum çerezler kullanılmaktadır.",
            analyticCookies: "Analitik Çerezler",
            analyticCookiesText: "Yalnızca izin vermeniz halinde, siteyi nasıl kullandığınızı anlamamıza yardımcı olan anonim kullanım istatistikleri toplanır. Bu veriler kişisel bilgiler içermez ve sizinle ilişkilendirilemez.",
            security: "Güvenlik",
            securityText: "Tüm hesaplamalar tarayıcınızda yerel olarak yapıldığından, girdiğiniz veriler internet üzerinden iletilmez ve sunucularımızda saklanmaz. Bu, verilerinizin güvenliğini maksimum düzeyde sağlar.",
            contact: "İletişim",
            contactText: "Bu gizlilik politikası hakkında sorularınız varsa, lütfen bizimle iletişime geçin.",
            close: "Kapat",
            accept: "KABUL ET",
            decline: "REDDET"
        },
        faq: {
            title: "Sık Sorulan Sorular",
            netSalaryCalc: {
                question: "{year} yılında net maaş nasıl hesaplanır?",
                answer: "{year} yılı net maaş hesaplaması için brüt maaştan sırasıyla:\n\n" +
                       "1. SGK Primi (%14)\n" +
                       "2. İşsizlik Sigortası (%1)\n" +
                       "3. Gelir Vergisi (dilime göre %15-40)\n" +
                       "4. Damga Vergisi (%0.759)\n\n" +
                       "kesintileri yapılır. Asgari ücret muafiyeti de hesaplamaya dahil edilir."
            },
            taxBrackets: {
                question: "{year} vergi dilimleri nelerdir?",
                answer: "{year} yılı gelir vergisi dilimleri şu şekildedir:\n\n" +
                       "• 0-70.000 TL arası: %15\n" +
                       "• 70.000-150.000 TL arası: %20\n" +
                       "• 150.000-550.000 TL arası: %27\n" +
                       "• 550.000 TL üzeri: %35"
            },
            minimumWage: {
                question: "{year} asgari ücret muafiyeti nedir?",
                answer: "{year} yılında asgari ücret tutarı kadar olan kısım gelir vergisinden muaf tutulmaktadır. Bu muafiyet sayesinde asgari ücret tutarına kadar olan kısım için gelir vergisi hesaplanmaz."
            },
            sgkPremium: {
                question: "SGK primi nedir ve nasıl hesaplanır?",
                answer: "SGK primi, çalışanın brüt maaşının %14'ü oranında kesilen ve sosyal güvenlik sistemine aktarılan zorunlu bir kesintedir. SGK tavanını aşan maaşlarda tavan üstü kısım için kesinti yapılmaz."
            },
            stampTax: {
                question: "Damga vergisi nedir?",
                answer: "Damga vergisi, ücret ödemelerinden %0.759 oranında kesilen yasal bir vergidir. Brüt maaş üzerinden hesaplanır ve her ay düzenli olarak kesilir."
            },
            detailedExample: {
                question: "Örnek bir maaş hesaplaması nasıl yapılır?",
                answer: "Örnek: 30.000 TL brüt maaş için hesaplama:\n\n" +
                       "1. SGK Primi: 30.000 × 0.14 = 4.200 TL\n" +
                       "2. İşsizlik Sigortası: 30.000 × 0.01 = 300 TL\n" +
                       "3. Gelir Vergisi Matrahı: 30.000 - 4.200 - 300 = 25.500 TL\n" +
                       "4. Gelir Vergisi: Dilime göre hesaplanır\n" +
                       "5. Damga Vergisi: 30.000 × 0.00759 = 227,70 TL\n\n" +
                       "Asgari ücret muafiyeti ve kümülatif vergi dilimleri de hesaplamaya dahil edilir."
            },
            yearComparison: {
                question: "{year} yılı vergi değişiklikleri nelerdir?",
                answer: "Önceki yıla göre başlıca değişiklikler:\n\n" +
                       "1. Vergi Dilimi Limitleri: Yeni limitler belirlendi\n" +
                       "2. Asgari Ücret: Artış yapıldı\n" +
                       "3. SGK Tavan Ücreti: Güncellendi\n" +
                       "4. Vergi Muafiyetleri: Asgari ücret muafiyeti devam ediyor\n\n" +
                       "Bu değişiklikler net maaş hesaplamalarını doğrudan etkiler."
            },
            taxCalculationDetail: {
                question: "Vergi dilimleri nasıl uygulanır?",
                answer: "Vergi dilimleri kümülatif olarak hesaplanır:\n\n" +
                       "Örnek: 200.000 TL'lik yıllık gelir için:\n" +
                       "1. 0-70.000 TL arası → %15\n" +
                       "2. 70.000-150.000 TL arası → %20\n" +
                       "3. 150.000-200.000 TL arası → %27\n\n" +
                       "Her dilim kendi oranıyla vergilendirilir ve toplam vergi bunların toplamıdır."
            },
            commonScenarios: {
                question: "Farklı maaş senaryoları nasıl hesaplanır?",
                answer: "1. Asgari Ücretli Çalışan:\n" +
                       "• Tüm gelir vergisi muafiyeti\n" +
                       "• Sadece SGK ve damga vergisi kesintisi\n\n" +
                       "2. Orta Gelirli Çalışan (25.000 TL):\n" +
                       "• Kısmi gelir vergisi muafiyeti\n" +
                       "• Normal vergi dilimi uygulaması\n\n" +
                       "3. Yüksek Gelirli Çalışan (100.000 TL):\n" +
                       "• SGK tavan ücreti uygulaması\n" +
                       "• Yüksek vergi dilimi etkisi"
            },
            sgkCeiling: {
                question: "SGK tavan ücreti nedir ve nasıl uygulanır?",
                answer: "SGK tavan ücreti, SGK primi kesintisinin maksimum sınırıdır.\n\n" +
                       "Örnek:\n" +
                       "• SGK Tavan Ücreti: 75.000 TL\n" +
                       "• Brüt Maaş: 100.000 TL\n" +
                       "• SGK Primi: 75.000 × 0.14 = 10.500 TL\n\n" +
                       "100.000 TL yerine tavan ücret olan 75.000 TL üzerinden hesaplanır."
            },
            advancedScenarios: {
                question: "Özel durumlarda maaş hesaplaması nasıl yapılır?",
                answer: "1. Yıl Ortası Zam Durumu:\n" +
                       "• İlk 6 ay: 30.000 TL brüt maaş\n" +
                       "• Son 6 ay: 35.000 TL brüt maaş\n" +
                       "• Vergi dilimi kümülatif olarak hesaplanır\n\n" +
                       "2. İkramiye/Prim Durumu:\n" +
                       "• Yıllık ikramiye: 60.000 TL\n" +
                       "• Vergi dilimine etkisi hesaplanır\n" +
                       "• SGK tavanı kontrolü yapılır\n\n" +
                       "3. Yarı Zamanlı Çalışma:\n" +
                       "• Brüt maaş: 15.000 TL\n" +
                       "• Asgari ücret muafiyeti tam uygulanır\n" +
                       "• SGK primi oransal hesaplanır"
            },
            historicalComparison: {
                question: "Son 3 yılın vergi ve kesinti değişiklikleri nelerdir?",
                answer: "2023-2025 Karşılaştırması:\n\n" +
                       "1. Asgari Ücret:\n" +
                       "• 2023: 8.506 TL\n" +
                       "• 2024: 17.002 TL\n" +
                       "• 2025: 20.002 TL\n\n" +
                       "2. SGK Tavan Ücreti:\n" +
                       "• 2023: 63.795 TL\n" +
                       "• 2024: 127.515 TL\n" +
                       "• 2025: 150.015 TL\n\n" +
                       "3. Vergi Dilimi Değişimleri:\n" +
                       "• 2023: 4 dilim (%15-%40)\n" +
                       "• 2024: 4 dilim (%15-%40)\n" +
                       "• 2025: 4 dilim (%15-%40)"
            },
            technicalDetails: {
                question: "Vergi hesaplamalarının teknik detayları nelerdir?",
                answer: "1. Gelir Vergisi Hesaplama Formülü:\n" +
                       "Vergi = Σ(Dilim Matrahı × Dilim Oranı)\n\n" +
                       "2. SGK Matrah Hesaplama:\n" +
                       "Matrah = min(Brüt Ücret, SGK Tavan Ücreti)\n\n" +
                       "3. Kümülatif Vergi Matrahı:\n" +
                       "Matrah(n) = Σ(Önceki Aylar) + Güncel Ay\n\n" +
                       "4. Asgari Ücret İstisnası:\n" +
                       "İstisna = min(Brüt Ücret, Asgari Ücret) × Vergi Oranı\n\n" +
                       "5. Damga Vergisi:\n" +
                       "DV = Brüt Ücret × 0.00759"
            },
            employerCosts: {
                question: "İşveren maliyetleri nasıl hesaplanır?",
                answer: "İşveren için toplam maliyet şu kalemlerden oluşur:\n\n" +
                       "1. İşveren SGK Primi (%15.5):\n" +
                       "• 30.000 TL maaş için: 4.650 TL\n" +
                       "• SGK tavanı uygulanır\n\n" +
                       "2. İşveren İşsizlik Sigortası (%2):\n" +
                       "• 30.000 TL maaş için: 600 TL\n\n" +
                       "3. Toplam İşveren Maliyeti:\n" +
                       "• Brüt Maaş + SGK + İşsizlik\n" +
                       "• 30.000 + 4.650 + 600 = 35.250 TL"
            },
            bonusCalculation: {
                question: "İkramiye ve prim ödemeleri nasıl vergilendirilir?",
                answer: "İkramiye ve primlerin vergilendirilmesi:\n\n" +
                       "1. Yıllık İkramiye Örneği:\n" +
                       "• Brüt İkramiye: 60.000 TL\n" +
                       "• Mevcut Vergi Dilimi: %27\n" +
                       "• SGK Kesintisi: 8.400 TL\n" +
                       "• Gelir Vergisi: 13.932 TL\n\n" +
                       "2. Performans Primi Örneği:\n" +
                       "• Brüt Prim: 20.000 TL\n" +
                       "• Tüm kesintiler normal maaş gibi\n" +
                       "• Kümülatif vergi dilimi etkilenir"
            },
            salaryChart: {
                title: "Aylık Maaş Grafiği",
                grossSalary: "Brüt Maaş",
                netSalary: "Net Maaş",
                month: "Ay",
                amount: "Miktar (TL)",
                tooltip: {
                    gross: "Brüt Maaş",
                    net: "Net Maaş",
                    month: "Ay"
                }
            }
        },
        initialTableContent: {
            description: "Net maaş hesaplama aracımız, brüt maaşınızdan yapılacak tüm kesintileri ve vergi dilimlerini otomatik olarak hesaplar. Yukarıdaki alana brüt maaşınızı girerek detaylı hesaplamayı başlatabilirsiniz.",
            features: "Özellikler",
            feature1: "SGK primi, işsizlik sigortası ve damga vergisi hesaplamaları",
            feature2: "Kümülatif vergi dilimi hesaplaması",
            feature3: "Asgari ücret muafiyeti dahil güncel hesaplamalar",
            feature4: "Aylık ve yıllık bazda detaylı maaş analizi"
        }
    },
    en: {
        title: "Net Salary Calculator",
        subtitle: "Detailed calculation including current tax brackets and minimum wage exemption",
        calculate: "Calculate",
        grossSalary: "Gross Salary",
        grossSalaryPlaceholder: "Enter your monthly gross salary",
        netSalary: "Net Salary",
        sgkPremium: "Social Security Premium",
        unemploymentInsurance: "Unemployment Insurance",
        stampTax: "Stamp Tax",
        incomeTax: "Income Tax",
        taxBracket: "Tax Bracket",
        month: "Month",
        total: "Total",
        average: "Average",
        grossRatio: "Ratio to Gross",
        taxBrackets: "{year} Income Tax Brackets",
        andAbove: "and above",
        note: "Note",
        minimumWageNote: "Cannot calculate salary below minimum wage",
        enterValidSalary: "Please enter a valid salary",
        salaryBelowMinimumWage: "Entered salary cannot be below minimum wage ({asgariUcret} TRY)",
        ceiling: "Ceiling",
        calculatedStampTax: "Calculated Stamp Tax",
        deductedStampTax: "Deducted Stamp Tax",
        calculatedIncomeTax: "Calculated Income Tax",
        deductedIncomeTax: "Deducted Income Tax",
        minimumWageExemption: "Minimum Wage Exemption",
        taxBracketDetails: "Tax Bracket Details",
        fromBracket: "from bracket",
        months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        disclaimer: "This calculation tool is for informational purposes only. Calculations are based on approximate values and may differ from actual values. Particularly, minimum wage exemption, tax brackets, and SGK ceiling amounts may change during the year. For accurate information, please consult your financial advisor or visit the official websites of relevant institutions (GİB, SGK). No responsibility is accepted for any damages that may arise from calculations made through this tool.",
        privacy: {
            title: "Privacy Policy",
            lastUpdated: "Last Updated: November 28, 2024",
            introduction: "Introduction",
            introductionText: "As a Net Salary Calculator tool, we value your privacy. This privacy policy provides information about how we use and protect your data when you use our site.",
            dataCollection: "Data Collection and Usage",
            salaryCalculations: "Salary Calculations",
            salaryCalculationsText: "All salary information you enter and calculations performed are processed locally in your browser. This information is not sent to our servers or stored in any way.",
            personalData: "Personal Data",
            personalDataText: "Our site does not collect or store any personal data. All operations are performed locally in your browser.",
            cookiesAndAnalytics: "Cookies and Analytics",
            necessaryCookies: "Necessary Cookies",
            necessaryCookiesText: "We use the minimum necessary cookies required for the site to function properly.",
            analyticCookies: "Analytics Cookies",
            analyticCookiesText: "Only with your consent, we collect anonymous usage statistics to help us understand how you use the site. This data contains no personal information and cannot be linked to you.",
            security: "Security",
            securityText: "Since all calculations are performed locally in your browser, your data is never transmitted over the internet or stored on our servers. This ensures maximum security of your information.",
            contact: "Contact",
            contactText: "If you have any questions about this privacy policy, please contact us.",
            close: "Close",
            accept: "ACCEPT",
            decline: "DECLINE"
        },
        faq: {
            title: "Frequently Asked Questions",
            netSalaryCalc: {
                question: "How is net salary calculated in {year}?",
                answer: "For {year} net salary calculation, the following deductions are made from gross salary:\n\n" +
                       "1. Social Security Premium (14%)\n" +
                       "2. Unemployment Insurance (1%)\n" +
                       "3. Income Tax (15-40% based on bracket)\n" +
                       "4. Stamp Tax (0.759%)\n\n" +
                       "Minimum wage exemption is also included in the calculation."
            },
            taxBrackets: {
                question: "What are the {year} tax brackets?",
                answer: "Income tax brackets for {year} are as follows:\n\n" +
                       "• 0-70,000 TRY: 15%\n" +
                       "• 70,000-150,000 TRY: 20%\n" +
                       "• 150,000-550,000 TRY: 27%\n" +
                       "• Above 550,000 TRY: 35%"
            },
            minimumWage: {
                question: "What is the {year} minimum wage exemption?",
                answer: "In {year}, the amount equal to minimum wage is exempt from income tax. Thanks to this exemption, no income tax is calculated for the portion up to the minimum wage amount."
            },
            sgkPremium: {
                question: "What is SGK premium and how is it calculated?",
                answer: "SGK premium is a mandatory deduction of 14% from the employee's gross salary that is transferred to the social security system. For salaries exceeding the SGK ceiling, no deduction is made for the portion above the ceiling."
            },
            stampTax: {
                question: "What is stamp tax?",
                answer: "Stamp tax is a legal tax deducted at a rate of 0.759% from salary payments. It is calculated on gross salary and is deducted regularly every month."
            },
            detailedExample: {
                question: "How is a sample salary calculation done?",
                answer: "Example: For a gross salary of 30,000 TL:\n\n" +
                       "1. SGK Premium: 30,000 × 0.14 = 4,200 TL\n" +
                       "2. Unemployment Insurance: 30,000 × 0.01 = 300 TL\n" +
                       "3. Income Tax Base: 30,000 - 4,200 - 300 = 25,500 TL\n" +
                       "4. Income Tax: Calculated based on tax brackets\n" +
                       "5. Stamp Tax: 30,000 × 0.00759 = 227.70 TL\n\n" +
                       "Minimum wage exemption and cumulative tax brackets are also included in the calculation."
            },
            yearComparison: {
                question: "What are the tax changes in {year}?",
                answer: "Main changes compared to previous year:\n\n" +
                       "1. Tax Bracket Limits: New limits set\n" +
                       "2. Minimum Wage: Increased\n" +
                       "3. SGK Ceiling: Updated\n" +
                       "4. Tax Exemptions: Minimum wage exemption continues\n\n" +
                       "These changes directly affect net salary calculations."
            },
            taxCalculationDetail: {
                question: "How are tax brackets applied?",
                answer: "Tax brackets are calculated cumulatively:\n\n" +
                       "Example: For annual income of 200,000 TL:\n" +
                       "1. 0-70,000 TL → 15%\n" +
                       "2. 70,000-150,000 TL → 20%\n" +
                       "3. 150,000-200,000 TL → 27%\n\n" +
                       "Each bracket is taxed at its own rate and total tax is the sum."
            },
            commonScenarios: {
                question: "How are different salary scenarios calculated?",
                answer: "1. Minimum Wage Employee:\n" +
                       "• Full income tax exemption\n" +
                       "• Only SGK and stamp tax deductions\n\n" +
                       "2. Mid-Income Employee (25,000 TL):\n" +
                       "• Partial income tax exemption\n" +
                       "• Normal tax bracket application\n\n" +
                       "3. High-Income Employee (100,000 TL):\n" +
                       "• SGK ceiling application\n" +
                       "• High tax bracket effect"
            },
            sgkCeiling: {
                question: "What is SGK ceiling and how is it applied?",
                answer: "SGK ceiling is the maximum limit for SGK premium deductions.\n\n" +
                       "Example:\n" +
                       "• SGK Ceiling: 75,000 TL\n" +
                       "• Gross Salary: 100,000 TL\n" +
                       "• SGK Premium: 75,000 × 0.14 = 10,500 TL\n\n" +
                       "Calculated on ceiling amount of 75,000 TL instead of 100,000 TL."
            },
            advancedScenarios: {
                question: "How are salaries calculated in special cases?",
                answer: "1. Mid-Year Raise Scenario:\n" +
                       "• First 6 months: 30,000 TL gross salary\n" +
                       "• Last 6 months: 35,000 TL gross salary\n" +
                       "• Tax bracket calculated cumulatively\n\n" +
                       "2. Bonus/Premium Scenario:\n" +
                       "• Annual bonus: 60,000 TL\n" +
                       "• Tax bracket effect is calculated\n" +
                       "• SGK ceiling is checked\n\n" +
                       "3. Part-Time Work:\n" +
                       "• Gross salary: 15,000 TL\n" +
                       "• Full minimum wage exemption applies\n" +
                       "• SGK premium calculated proportionally"
            },
            historicalComparison: {
                question: "What are the tax and deduction changes over the last 3 years?",
                answer: "2023-2025 Comparison:\n\n" +
                       "1. Minimum Wage:\n" +
                       "• 2023: 8,506 TL\n" +
                       "• 2024: 17,002 TL\n" +
                       "• 2025: 20,002 TL\n\n" +
                       "2. SGK Ceiling:\n" +
                       "• 2023: 63,795 TL\n" +
                       "• 2024: 127,515 TL\n" +
                       "• 2025: 150,015 TL\n\n" +
                       "3. Tax Bracket Changes:\n" +
                       "• 2023: 4 brackets (15%-40%)\n" +
                       "• 2024: 4 brackets (15%-40%)\n" +
                       "• 2025: 4 brackets (15%-40%)"
            },
            technicalDetails: {
                question: "What are the technical details of tax calculations?",
                answer: "1. Income Tax Calculation Formula:\n" +
                       "Tax = Σ(Bracket Base × Bracket Rate)\n\n" +
                       "2. SGK Base Calculation:\n" +
                       "Base = min(Gross Salary, SGK Ceiling)\n\n" +
                       "3. Cumulative Tax Base:\n" +
                       "Base(n) = Σ(Previous Months) + Current Month\n\n" +
                       "4. Minimum Wage Exemption:\n" +
                       "Exemption = min(Gross Salary, Minimum Wage) × Tax Rate\n\n" +
                       "5. Stamp Tax:\n" +
                       "ST = Gross Salary × 0.00759"
            },
            employerCosts: {
                question: "How are employer costs calculated?",
                answer: "Total employer cost consists of:\n\n" +
                       "1. Employer SGK Premium (15.5%):\n" +
                       "• For 30,000 TL salary: 4,650 TL\n" +
                       "• SGK ceiling applies\n\n" +
                       "2. Employer Unemployment Insurance (2%):\n" +
                       "• For 30,000 TL salary: 600 TL\n\n" +
                       "3. Total Employer Cost:\n" +
                       "• Gross Salary + SGK + Unemployment\n" +
                       "• 30,000 + 4,650 + 600 = 35,250 TL"
            },
            bonusCalculation: {
                question: "How are bonuses and premium payments taxed?",
                answer: "Taxation of bonuses and premiums:\n\n" +
                       "1. Annual Bonus Example:\n" +
                       "• Gross Bonus: 60,000 TL\n" +
                       "• Current Tax Bracket: 27%\n" +
                       "• SGK Deduction: 8,400 TL\n" +
                       "• Income Tax: 13,932 TL\n\n" +
                       "2. Performance Bonus Example:\n" +
                       "• Gross Bonus: 20,000 TL\n" +
                       "• All deductions same as regular salary\n" +
                       "• Affects cumulative tax bracket"
            },
            salaryChart: {
                title: "Monthly Salary Chart",
                grossSalary: "Gross Salary",
                netSalary: "Net Salary",
                month: "Month",
                amount: "Amount (TL)",
                tooltip: {
                    gross: "Gross Salary",
                    net: "Net Salary",
                    month: "Month"
                }
            }
        },
        initialTableContent: {
            description: "Our net salary calculator automatically calculates all deductions and tax brackets from your gross salary. Enter your gross salary in the field above to start detailed calculation.",
            features: "Features",
            feature1: "Social security premium, unemployment insurance and stamp tax calculations",
            feature2: "Cumulative tax bracket calculation",
            feature3: "Current calculations including minimum wage exemption",
            feature4: "Detailed salary analysis on monthly and yearly basis"
        }
    },
    ar: {
        title: "حاسبة الراتب الصافي",
        subtitle: "حساب مفصل يشمل شرائح الضريبة لحالية وإعفاء الحد الأدنى للأجور",
        calculate: "احسب",
        grossSalary: "الراتب الإجمالي",
        grossSalaryPlaceholder: "أدخل راتبك الإجمالي الشهري",
        netSalary: "الراتب الصافي",
        sgkPremium: "قسط الضمان الاجتماعي",
        unemploymentInsurance: "تأمين البطالة",
        stampTax: "ضريبة الدمغة",
        incomeTax: "ضريبة الدخل",
        taxBracket: "شريحة الضريبة",
        month: "الشهر",
        total: "المجموع",
        average: "المتوسط",
        grossRatio: "النسبة إلى الإجمالي",
        taxBrackets: "شرائح ضريبة الدخل {year}",
        andAbove: "وما فوق",
        note: "ملاحظة",
        minimumWageNote: "لا يمكن حساب راتب أقل من الحد الأدنى للأجور",
        enterValidSalary: "الرجاء إدخال راتب صحيح",
        salaryBelowMinimumWage: "لا يمكن أن يكون الراتب المدخل أقل من الحد الأدنى للأجور ({asgariUcret} ليرة تركية)",
        ceiling: "الحد الأقصى",
        calculatedStampTax: "ضريبة الدمغة المحسوبة",
        deductedStampTax: "ضريبة الدمغة المخفضة",
        calculatedIncomeTax: "ضريبة الدخل المحسوبة",
        deductedIncomeTax: "ضريبة الدخل المخفضة",
        minimumWageExemption: "إعفاء الحد الأدنى للأجور",
        taxBracketDetails: "تفاصيل شريحة الضريبة",
        fromBracket: "من الشريحة",
        months: ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"],
        disclaimer: "هذه الأداة الحسابية هي لأغراض إعلامية فقط. تستند الحسابات إلى قيم تقريبية وقد تختلف عن القيم الفعلية. على وجه الخصوص، قد يتغير إعفاء الحد الأدنى للأجور وشرائح الضرائب والحد الأقصى للضمان الاجتماعي خلال العام. للحصول على معلومات دقيقة، يرجى استشارة مستشارك المالي أو زيارة المواقع الرسمية للمؤسسات ذات الصلة. لا نتحمل أي مسؤولية عن أي أضرار قد تنشأ عن الحسابات التي تتم من خلال هذه الأداة.",
        privacy: {
            title: "سياسة الخصوصية",
            lastUpdated: "آخر تحديث: 28 نوفمبر 2024",
            introduction: "مقدمة",
            introductionText: "كأداة لحساب الراتب الصافي، نحن نقدر خصوصيتك. توفر سياسة الخصوصية هذه معلومات حول كيفية استخدامنا وحماية بياناتك عند استخدام موقعنا.",
            dataCollection: "جمع البيانات واستخدامها",
            salaryCalculations: "حساب��ت الرواتب",
            salaryCalculationsText: "تتم معالجة جميع معلومات الراتب التي تدخلها والحسابات التي تتم محليًا في متصفحك. لا يتم إرسال هذه المعلومات إلى خوادمنا أو تخزينها بأي شكل من الأشكال.",
            personalData: "البيانات الشخصية",
            personalDataText: "موقعنا لا يجمع أو يخزن أي بيانات شخصية. تتم جميع العمليات محليًا في متصفحك.",
            cookiesAndAnalytics: "ملفات تعريف الارتباط والتحليلات",
            necessaryCookies: "ملفات تعريف الارتباط الضرورية",
            necessaryCookiesText: "نستخدم الحد الأدنى من ملفات تعريف الارتباط الضرورية المطلوبة لعمل الموقع بشكل صحيح.",
            analyticCookies: "ملفات تعريف الارتباط التحليلية",
            analyticCookiesText: "فقط بموافقتك، نجمع إحصائيات استخدام مجهولة لمساعدتنا على فهم كيفية استخدامك للموقع. لا تحتوي هذه البيانات على معلومات شخصية ولا يمكن ربطها بك.",
            security: "ا��أمان",
            securityText: "نظرا لأن جميع الحسابات تتم محليًا في متصفحك، فلا يتم نقل بياناتك عبر الإنترنت أو تخزينها على خوادمنا. هذا يضمن أقصى درجات الأمان لمعلوماتك.",
            contact: "اتصل بنا",
            contactText: "إذا كان لديك أي أسئلة حول سياسة الخصوصية هذه، يرجى الاتصال بنا.",
            close: "إغلاق",
            accept: "قبول",
            decline: "رفض"
        },
        faq: {
            title: "الأسئلة الشائعة",
            netSalaryCalc: {
                question: "كيف يتم حساب الراتب الصافي في عام {year}؟",
                answer: "لحساب الراتب الصافي لعام {year}، يتم خصم ما يلي من الراتب الإجمالي:\n\n" +
                       "1. قسط الضمان الاجتماعي (14%)\n" +
                       "2. تأمين البطالة (1%)\n" +
                       "3. ضريبة الدخل (15-40% حسب الشريحة)\n" +
                       "4. ضريبة الدمغة (0.759%)\n\n" +
                       "يتم تضمين إعفاء الحد الأدنى للأجور في الحساب."
            },
            taxBrackets: {
                question: "ما هي شرائح الضريبة لعام {year}؟",
                answer: "شرائح ضريبة الدخل لعام {year} هي كما يلي:\n\n" +
                       "• 0-70,000 ليرة تركية: 15%\n" +
                       "• 70,000-150,000 ليرة تركية: 20%\n" +
                       "• 150,000-550,000 ليرة تركية: 27%\n" +
                       "• أكثر من 550,000 ليرة تركية: 35%"
            },
            minimumWage: {
                question: "ما هو إعفاء الحد الأدنى للأجور لعام {year}؟",
                answer: "في عام {year}، يتم إعفاء المبلغ المساوي للحد الأدنى للأجور من ضريبة الدخل. بفضل هذا الإعفاء، لا يتم احتساب ضريبة الدخل للجزء حتى مبلغ الحد الأدنى للأجور."
            },
            sgkPremium: {
                question: "ما هو قسط الضمان الاجتماعي وكيف يتم حسابه؟",
                answer: "قسط الضمان الاجتماعي هو خصم إلزامي بنسبة 14% من الراتب الإجمالي للموظف يتم تحويله إلى نظام الضمان الاجتماعي. للرواتب التي تتجاوز سقف الضمان الاجتماعي، لا يتم إجراء خصم للجزء اذي يتجاوز السقف."
            },
            stampTax: {
                question: "ما هي ضريبة الدمغة؟",
                answer: "ضريبة الدمغة هي ضريبة قانونية تخصم بنسبة 0.759% من مدفوعات الرواتب. يتم حسابها على الراتب الإجمالي ويتم خصمها بشكل منتظم كل شهر."
            },
            detailedExample: {
                question: "كيف يتم حساب الراتب الصافي؟",
                answer: "مثال: لراتب إجمالي قدره 30,000 ليرة تركية:\n\n" +
                       "1. قسط الضمان الاجتماعي: 30,000 × 0.14 = 4,200 ليرة تركية\n" +
                       "2. تأمين البطالة: 30,000 × 0.01 = 300 ليرة تركية\n" +
                       "3. مبلغ ضريبة الدخل: 30,000 - 4,200 - 300 = 25,500 ليرة تركية\n" +
                       "4. ضريبة الدخل: تم حسابها بناءً على شرائح الضريبة\n" +
                       "5. ضريبة الدمغة: 30,000 × 0.00759 = 227,70 ليرة تركية\n\n" +
                       "يتم تضمين إعفاء الحد الأدنى للأجور وشرائح الضريبة التراكمية في الحساب."
            },
            yearComparison: {
                question: "ما هي التغييرات في الضريبة في عام {year}؟",
                answer: "التغييرات الرئيسية مقابل العام السابق:\n\n" +
                       "1. حدود شريحة الضريبة: حدود جديدة تم تحديدها\n" +
                       "2. الحد الأدنى للأجور: زيادة\n" +
                       "3. سقف الضمان الاجتماعي: تم التحديث\n" +
                       "4. الإعفاءات الضريبية: إعفاء الحد الأدنى للأجور يستمر\n\n" +
                       "تغييرات هذه تؤثر على حسابات الراتب الصافي بشكل مباشر."
            },
            taxCalculationDetail: {
                question: "كيف يتم تطبيق شرائح الضريب؟",
                answer: "تتم حساب شرائح الضريبة تراكميًا:\n\n" +
                       "مثال: لدخل سنوي قدره 200,000 ليرة تركية:\n" +
                       "1. 0-70,000 TL → 15%\n" +
                       "2. 70,000-150,000 TL → 20%\n" +
                       "3. 150,000-200,000 TL → 27%\n\n" +
                       "يتم ضريبة كل شريحة بنسبتها الخاصة ومجموع الضريبة هو مجموعها."
            },
            commonScenarios: {
                question: "كيف يتم حساب سيناريوهات الراتب المختلفة؟",
                answer: "1. موظف الحد الأدنى للأجور:\n" +
                       "• إعفاء ضريبة الدخل الكلي\n" +
                       "• فقط خصمات ضمان اجتماعي وضريبة الدمغة\n\n" +
                       "2. موظف الدخل المتوسط (25,000 TL):\n" +
                       "• إعفاء ضريبة الدخل الجزئي\n" +
                       "• تطبيق شريحة الضريبة العادية\n\n" +
                       "3. موظف الدخل المرتفع (100,000 TL):\n" +
                       "• تطبيق سقف الضمان الاجتماعي\n" +
                       "• تأثير شريحة الضريبة المرتفعة"
            },
            sgkCeiling: {
                question: "ما هو سقف الضمان الاجتماعي وكيف يتم تطبيقه؟",
                answer: "سقف الضمان الاجتماعي هو الحد الأقصى لخصم ضمان اجتماعي.\n\n" +
                       "مثال:\n" +
                       "• سقف الضمان الاجتماعي: 75,000 TL\n" +
                       "• الراتب الإجمالي: 100,000 TL\n" +
                       "• ضمان اجتماعي: 75,000 × 0.14 = 10,500 TL\n\n" +
                       "يتم حسابه على مبلغ 75,000 TL بدلاً من 100,000 TL."
            },
            advancedScenarios: {
                question: "كيف يتم حساب الرواتب في الحالات الخاصة؟",
                answer: "1. سيناريو الزيادة في منتصف العام:\n" +
                       "• الستة أشهر الأولى: 30,000 TL إجمالي الراتب\n" +
                       "• الستة أشهر الأخيرة: 35,000 TL إجمالي الراتب\n" +
                       "• يتم حساب شريحة الضريبة تراكمياً\n\n" +
                       "2. سيناريو المكافأة/العلاوة:\n" +
                       "• المكافأة السنوية: 60,000 TL\n" +
                       "• يتم حساب تأثير شريحة الضريبة\n" +
                       "• يتم التحقق من سقف الضمان الاجتماعي\n\n" +
                       "3. العمل بدوام جزئي:\n" +
                       "• إجمالي الراتب: 15,000 TL\n" +
                       "• يطبق إعفاء الحد الأدنى للأجور بالكامل\n" +
                       "• يتم حساب قسط الضمان الاجتماعي نسبياً"
            },
            historicalComparison: {
                question: "ما هي تغييرات الضرائب والخصومات خلال السنوات الثلا�� الماضية؟",
                answer: "مقارنة 2023-2025:\n\n" +
                       "1. الحد الأدنى للأجور:\n" +
                       "• 2023: 8,506 ليرة تركية\n" +
                       "• 2024: 17,002 ليرة تركية\n" +
                       "• 2025: 20,002 ليرة تركية\n\n" +
                       "2. سقف الضمان الاجتماعي:\n" +
                       "• 2023: 63,795 ليرة تركية\n" +
                       "• 2024: 127,515 ليرة تركية\n" +
                       "• 2025: 150,015 ليرة تركية\n\n" +
                       "3. تغييرات شرائح الضريبة:\n" +
                       "• 2023: 4 شرائح (15%-40%)\n" +
                       "• 2024: 4 شرائح (15%-40%)\n" +
                       "• 2025: 4 شرائح (15%-40%)"
            },
            technicalDetails: {
                question: "ما هي التفاصيل التقنية لحسابات الضرائب؟",
                answer: "1. صيغة حساب ضريبة الدخل:\n" +
                       "الضريبة = Σ(أساس الشريحة × معدل الشريحة)\n\n" +
                       "2. حساب أساس الضمان الاجتماعي:\n" +
                       "الأساس = الحد الأدنى(الراتب الإجمالي، سقف الضمان الاجتماعي)\n\n" +
                       "3. أساس الضريبة التراكمي:\n" +
                       "الأساس(n) = Σ(الأشهر السابقة) + الشهر الحالي\n\n" +
                       "4. إعفاء الحد الأدنى للأجور:\n" +
                       "الإعفاء = الحد الأدنى(الراتب الإجمالي، الحد الأدنى للأجور) × معدل الضريبة\n\n" +
                       "5. ضريبة الدمغة:\n" +
                       "ضريبة الدمغة = الراتب الإجمالي × 0.00759"
            },
            employerCosts: {
                question: "كيف يتم حساب تكاليف صاحب العمل؟",
                answer: "تتكون التكلفة الإجمالية لصاحب العمل من:\n\n" +
                       "1. قسط الضمان الاجتماعي لصاحب العمل (15.5%):\n" +
                       "• لراتب 30,000 TL: 4,650 TL\n" +
                       "• يطبق سقف الضمان الاجتماعي\n\n" +
                       "2. تأمين البطالة لصاحب العمل (2%):\n" +
                       "• لراتب 30,000 TL: 600 TL\n\n" +
                       "3. التكلفة الإجمالية لصاحب العمل:\n" +
                       "• الراتب الإجمالي + الضمان الاجتماعي + البطالة\n" +
                       "• 30,000 + 4,650 + 600 = 35,250 TL"
            },
            bonusCalculation: {
                question: "كيف يتم فرض الضرائب على المكافآت والعلاوات؟",
                answer: "فرض الضرائب على المكافآت والعلاوات:\n\n" +
                       "1. مثال المكافأة السنوية:\n" +
                       "• المكافأة الإجمالية: 60,000 ليرة تركية\n" +
                       "• شريحة الضريبة الحالية: 27%\n" +
                       "• خصم الضمان الاجتماعي: 8,400 ليرة تركية\n" +
                       "• ضريبة الدخل: 13,932 ليرة تركية\n\n" +
                       "2. مثال مكافأة الأداء:\n" +
                       "• المكافأة الإجمالية: 20,000 ليرة تركية\n" +
                       "• جميع الخصومات مثل الراتب العادي\n" +
                       "• تؤثر على شريحة الضريبة التراكمية"
            },
            salaryChart: {
                title: "الرسم البياني للراتب الشهري",
                grossSalary: "الراتب الإجمالي",
                netSalary: "الراتب الصافي",
                month: "الشهر",
                amount: "المبلغ (ليرة تركية)",
                tooltip: {
                    gross: "الراتب الإجمالي",
                    net: "الراتب الصافي",
                    month: "الشهر"
                }
            }
        },
        initialTableContent: {
            description: "تقوم حاسبة الراتب الصافي لدينا تلقائيًا بحساب جميع الخصومات وشرائح الضريبة من راتبك الإجمالي. أدخل راتبك الإجمالي في الحقل أعلاه لبدء الحساب التفصيلي.",
            features: "الميزات",
            feature1: "حسابات قسط الضمان الاجتماعي وتأمين البطالة وضريبة الدمغة",
            feature2: "حساب شريحة الضريبة التراكمية",
            feature3: "الحسابات الحالية بما في ذلك إعفاء الحد الأدنى للأجور",
            feature4: "تحليل تفصيلي للراتب على أساس شهري وسنوي"
        }
    }
}; 