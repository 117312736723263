import { getAnalytics, logEvent, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { app } from './config';

let analytics = null;

export const initializeAnalytics = (hasConsent = false) => {
    try {
        analytics = getAnalytics(app);
        setAnalyticsCollectionEnabled(analytics, hasConsent);
        
        // Log initial page view
        if (hasConsent) {
            logEvent(analytics, 'page_view', {
                page_title: document.title,
                page_location: window.location.href,
                page_path: window.location.pathname
            });
        }
    } catch (error) {
        console.error('Analytics initialization error:', error);
    }
};

export const logAnalyticsEvent = (eventName, params = {}) => {
    if (!analytics) return;
    
    try {
        logEvent(analytics, eventName, params);
    } catch (error) {
        console.error('Analytics event error:', error);
    }
};

export const trackCalculation = (_, __, year) => {
    if (!analytics) return;
    
    logEvent(analytics, 'salary_calculation', {
        year: year
    });
};

export const trackChartView = () => {
    logAnalyticsEvent('chart_view');
};

export const trackFAQView = (question) => {
    logAnalyticsEvent('faq_view', {
        question: question
    });
};

export const trackLanguageChange = (language) => {
    logAnalyticsEvent('language_change', {
        language: language
    });
};

export const trackYearChange = (year) => {
    if (!analytics) return;
    
    logEvent(analytics, 'year_changed', {
        year: year
    });
};

// Helper function to categorize salary ranges
const getSalaryRange = (grossSalary) => {
    if (grossSalary < 30000) return 'low';
    if (grossSalary < 100000) return 'medium';
    return 'high';
}; 